import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from '@material-ui/core/Snackbar';
import { ReactComponent as PhoneIcon } from '../assets/phone.svg';

class CallButton extends React.Component {
  state = {
    isLoading: false,
    openSnackbar: false,
    snackbarMessage: '',
  };

  handlePhoneCall = () => {
    this.setState({ isLoading: true });
    const endpoint = `${process.env.REACT_APP_COMMON_API_URL}/provider/make-call`;
    const phoneCodeMap = {
      CO: '57',
      MX: '52',
      PA: '507',
      CR: '506',
    };

    const addCountryCode = (phone, code) => {
      if (this.props.countryCode === 'PR') return phone;
      if (phone.startsWith(code)) return phone;
      return `${code}${phone.replace(/^0+/, '')}`;
    };

    const body = {
      source: addCountryCode(this.props.providerPhone || '', phoneCodeMap[this.props.countryCode]),
      destination: addCountryCode(this.props.phone, phoneCodeMap[this.props.countryCode]),
      accountName: this.props.accountName,
      countryCode: this.props.countryCode,
      callType: 'client',
    };

    fetch(endpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${process.env.REACT_APP_COMMON_API_KEY}`,
      },
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status !== false) {
          this.setState({
            isLoading: false,
            openSnackbar: true,
            snackbarMessage: 'Llamada iniciada con éxito',
          });
        } else {
          this.setState({
            isLoading: false,
            openSnackbar: true,
            snackbarMessage: 'Error al iniciar la llamada',
          });
        }
      })
      .catch((error) => {
        console.error('Error al iniciar la llamada:', error);
        this.setState({
          isLoading: false,
          openSnackbar: true,
          snackbarMessage: 'Error al iniciar la llamada',
        });
      });
  };

  handleCloseSnackbar = (_event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({ openSnackbar: false });
  };

  render() {
    return (
      <>
        {this.state.isLoading ? (
          <CircularProgress size={16} />
        ) : (
          <Tooltip title="Llamar" placement="top">
            <IconButton
              onClick={this.handlePhoneCall}
              aria-label="llamar"
              style={{
                padding: 0,
                width: 'auto',
                height: 'auto',
              }}
            >
              <PhoneIcon style={{ width: 16, height: 16 }} />
            </IconButton>
          </Tooltip>
        )}
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={this.state.openSnackbar}
          autoHideDuration={3000}
          onClose={this.handleCloseSnackbar}
          message={this.state.snackbarMessage}
          action={
            <IconButton size="small" aria-label="close" color="inherit" onClick={this.handleCloseSnackbar}>
              ×
            </IconButton>
          }
        />
      </>
    );
  }
}

export default CallButton;
